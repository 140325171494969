
/* eslint-disable no-undef */
import Vue from 'vue';

import {
    FuturesOrderSides,
    GenerateRestrictions,
    LimitOrder,
    OrderActiveFieldValues,
    OrderExpiries,
    OrderRestrictionFields,
    OrdersSides,
    OrdersTypes,
    RestrictionsAdapter,
} from 'Models/trading';
import { IAttributes } from 'Entities/publicPresenter/Attributes';
import { channelsCallbacks, ISubscription, subscribe, unsubscribeChannel } from 'Store/v2/PublicSocketData';
import MarketDataApi from 'Apis/MarketData';
import OrderBookRequest from 'Entities/publicPresenter/OrderBookRequest';
import SpotTradesRequest from 'Entities/publicPresenter/SpotTradesRequest';
import OrderBookLevel from 'Entities/publicPresenter/OrderBookLevel';
import TradingApi from 'Apis/Trading';
import TradingCommissionRateRequest from 'Entities/privatePresenter/TradingCommissionRateRequest';
import AccountTradingCommission from 'Entities/privatePresenter/AccountTradingCommission';
import PlaceOrderRequest from 'Entities/orderRegistrator/PlaceOrderRequest';

interface Data {
    ordersTypes: any;
    activeOrderTypeIndex: number;
    sides: any;
    futuresSides: any;
    activeSideIndex: number;
    expiries: any;
    activeExpiryIndex: number;
    quantity: number;
    price: number;
    total: number;
    activeFieldValues: any;
    activeField: any;
    isOrderPerforming: boolean;
    marketPrice: number;
    orderBook: {
        asks: OrderBookLevel[],
        bids: OrderBookLevel[],
        grouping: string;
        previousPlacement: string;
        previousPair: string;
    };
    orderBookSubscriptionIndex: ISubscription | null;
    tradesBookSubscriptionIndex: ISubscription | null;
    personalFeesRate: null | AccountTradingCommission;
    lastPlacementName: string;
    lastPairSymbol: string;
}

interface Methods {
    setActiveSideIndex: (index: number) => void;
    SET_ACTIVE_EXPIRY_INDEX: (data: number) => void;
    SET_ACTIVE_FIELD: (data: string) => void;
    SET_QUANTITY: (data: number) => void;
    SET_PRICE: (data: number) => void;
    SET_TOTAL: (data: number) => void;
    SET_IS_ORDER_PERFORMING_STATUS: (data: boolean) => void;
    SET_FEES_RATE: (data: null | AccountTradingCommission) => void;
    setIsOrderPerformingStatus: (data: boolean) => void;
    setActiveExpiryIndex: (data: number) => void;
    setActiveField: (data: string) => void;
    setQuantity: (data: number) => void;
    setPrice: (data: number) => void;
    setTotal: (data: number) => void;
    resetFields: () => void;
    validateOrder: () => void;
    performOrder: () => any;
    placeOrder: (data: any) => any;
    removeSnapshot: (placement: string, pair: string) => void;
    orderBookSubscribe: () => void;
    tradesSubscribe: () => void;
    getFeesRate: (data: boolean) => void;
    updateOrderBook: () => void;
}

interface Computed {
    getExpiries: any;
    getOrdersTypes: any;
    getActiveOrderTypeIndex: number;
    activeOrderType: any;
    getSides: any;
    getActiveSideIndex: number;
    activeSide: any;
    activeFuturesSide: any;
    orderRestrictions: IAttributes | Record<string, never>;
    getActiveExpiryIndex: number;
    activeExpiry: any;
    getActiveFieldValues: any;
    getActiveField: any;
    quantityBalanceFree: number;
    totalBalanceFree: number;
    getPrice: number;
    baseAssetPrecision: number;
    quoteAssetPrecision: number;
    quantityPrecision: number;
    pricePrecision: number;
    getQuantity: number;
    getTotal: number;
    totalEquivalent: number;
    currentCommissionPart: number;
    commissionPrecision: number;
    commissionAsset: string;
    currentCommission: number;
    currentCommissionQuotation: number;
    limitOrderRestrictions: any;
    activeOrderRestriction: any;
    activeOrderQuantityRestriction: any;
    activeOrderPriceRestriction: any;
    activeOrderTotalRestriction: any;
    quantityRestriction: any;
    priceRestriction: any;
    totalRestriction: any;
    getIsOrderPerforming: boolean;
    askPrice: number;
    bidPrice: number;
    marketSellPrice: number;
    marketBuyPrice: number;
}

export default Vue.extend<Data, Methods, Computed, any>({
    // state
    data() {
        return {
            lastPlacementName: '',
            lastPairSymbol: '',
            // trading state
            ordersTypes: Object.values(OrdersTypes),
            activeOrderTypeIndex: 0,
            sides: Object.values(OrdersSides),
            futuresSides: Object.values(FuturesOrderSides),
            activeSideIndex: 0,
            // limit state
            expiries: Object.values(OrderExpiries),
            activeExpiryIndex: 0,
            quantity: 0,
            price: 0,
            total: 0,
            activeFieldValues: OrderActiveFieldValues,
            activeField: OrderActiveFieldValues.QUANTITY,
            isOrderPerforming: false,
            // orderbook and trades state
            marketPrice: 0,
            orderBook: {
                asks: [],
                bids: [],
                grouping: '0.00000001',
                previousPlacement: '',
                previousPair: '',
            },
            orderBookSubscriptionIndex: null,
            tradesBookSubscriptionIndex: null,
            personalFeesRate: null,
        };
    },
    // getters
    computed: {
        // trading getters
        getExpiries() {
            const placementData = this.$store.state.Placements.placements.find(({ name }) => name.replace(' ', '_').toLowerCase() === this.placement.replace(' ', '_').toLowerCase());
            return this.expiries.filter((e) => {
                return placementData?.orderTypes.includes(`${this.getOrdersTypes[this.activeOrderTypeIndex].value}_${e.value}`);
            });
        },
        getOrdersTypes() {
            return this.ordersTypes
                .filter((type) => (
                    this.isCurrentPlacementFutures ? type.showInFutures : true
                ))
                .map((type) => (
                    {
                        disabled: !type.typeNames
                            .some((typeName) => this.$store.getters['Placements/getOrderTypesByPlacementName'](this.placement).some((placementOrderType) => placementOrderType.toUpperCase() === typeName.toUpperCase()))
                            || (!type.allowMultiOrder && this.isModal)
                            || (type.needLeverage && !this.maxLeverage),
                        ...type,
                    }
                ));
        },
        getActiveOrderTypeIndex() {
            return this.activeOrderTypeIndex;
        },
        activeOrderType() {
            return this.getOrdersTypes[this.getActiveOrderTypeIndex];
        },
        getSides() {
            if (this.placement.indexOf('FUTURES') === -1) {
                return this.sides;
            }
            return this.futuresSides;
        },

        getActiveSideIndex() {
            return this.activeSideIndex;
        },
        activeSide() {
            return this.getSides[this.getActiveSideIndex];
        },
        activeFuturesSide() {
            return this.futuresSides[this.getActiveSideIndex];
        },
        orderRestrictions() {
            return this.$store.getters['AssetPairs/GET_ASSET_PAIRS'].find(({ symbol }) => symbol === this.pair)?.placementAttributes ?? {};
        },
        // limit getters
        getActiveExpiryIndex() {
            return this.activeExpiryIndex;
        },
        activeExpiry() {
            return this.getExpiries[this.getActiveExpiryIndex];
        },
        getActiveFieldValues() {
            return this.activeFieldValues;
        },
        getActiveField() {
            return this.activeField;
        },
        quantityBalanceFree() {
            const placementId = this.$store.getters['Placements/getPlacementIdByName'](this.placement);
            const assetSymbol = this.pair.split('/')[0];
            const balance = this.$store.getters['Balances/GET_ACTIVE_ACCOUNT_BALANCE_BY_PARAMS'](placementId, assetSymbol);
            if (balance) {
                return balance.free < 0 ? 0 : balance.free;
            }
            return 0;
        },
        totalBalanceFree() {
            const placementId = this.$store.getters['Placements/getPlacementIdByName'](this.placement);
            const assetSymbol = this.pair.split('/')[1];
            const balance = this.$store.getters['Balances/GET_ACTIVE_ACCOUNT_BALANCE_BY_PARAMS'](placementId, assetSymbol);
            if (balance) {
                return balance.free < 0 ? 0 : balance.free;
            }
            return 0;
        },
        getPrice() {
            return this.price;
        },
        baseAssetPrecision() {
            const assetPair = this.$store.getters['AssetPairs/GET_ASSET_PAIRS'].find(({ symbol }) => symbol === this.pair);
            if (!assetPair) {
                return 0;
            }
            return assetPair.placementPrecisionQuantity;
        },
        quoteAssetPrecision() {
            const assetPair = this.$store.getters['AssetPairs/GET_ASSET_PAIRS'].find(({ symbol }) => symbol === this.pair);
            if (!assetPair) {
                return 0;
            }
            return assetPair.placementPrecisionPrice;
        },
        quantityPrecision() {
            return Math.min(
                this.baseAssetPrecision,
                this.quantityRestriction.stepSize ? this.quantityRestriction.stepSize.getPrecision() : Infinity,
            );
        },
        pricePrecision() {
            return Math.min(
                this.quoteAssetPrecision,
                this.priceRestriction.stepSize ? this.priceRestriction.stepSize.getPrecision() : Infinity,
            );
        },
        getQuantity() {
            const result = (this.getActiveField === this.getActiveFieldValues.QUANTITY
                ? this.quantity
                : (this.getTotal / this.getPrice) as any).round(this.quantityPrecision);

            return Number.isFinite(result) ? result : 0;
        },
        getTotal() {
            const result = (this.getActiveField === this.getActiveFieldValues.TOTAL
                ? this.total
                : (Number(this.getQuantity) * Number(this.getPrice)) as any).round(this.pricePrecision);

            return Number.isFinite(result) ? result : 0;
        },
        totalEquivalent() {
            if (this.activeSide.value === OrdersSides.BUY.value) {
                if (this.getPrice >= this.marketPrice) {
                    return this.$store.getters['Assets/GET_QUOTE'](
                        this.pair.split('/')[0],
                        this.getQuantity,
                    );
                }
                return this.$store.getters['Assets/GET_QUOTE'](
                    this.pair.split('/')[1],
                    this.getTotal,
                );
            } if (this.activeSide.value === OrdersSides.SELL.value) {
                if (this.getPrice <= this.marketPrice) {
                    return this.$store.getters['Assets/GET_QUOTE'](
                        this.pair.split('/')[0],
                        this.getQuantity,
                    );
                }
                return this.$store.getters['Assets/GET_QUOTE'](
                    this.pair.split('/')[1],
                    this.getTotal,
                );
            }
            return 0;
        },
        currentCommissionPart() {
            const placement = this.$store.getters['Placements/getPlacementByName'](this.placement);
            return placement?.commissionTaker / 100 ?? 0;
        },
        commissionPrecision() {
            return this.activeSide.value === OrdersSides.BUY.value
                ? this.baseAssetPrecision
                : this.quoteAssetPrecision;
        },
        commissionAsset() {
            return this.activeSide.value === OrdersSides.BUY.value
                ? this.pair.split('/')[0]
                : this.pair.split('/')[1];
        },
        currentCommission() {
            const result = (((this.activeSide.value === OrdersSides.BUY.value ? this.getQuantity : this.getTotal) * this.currentCommissionPart) as any)
                .round(this.commissionPrecision);

            return Number.isFinite(result) ? result : 0;
        },
        currentCommissionQuotation() {
            return this.$store.getters['Assets/GET_QUOTE'](this.commissionAsset, this.currentCommission);
        },
        limitOrderRestrictions() {
            return this.orderRestrictions[OrdersTypes.LIMIT.restriction];
        },
        activeOrderRestriction() {
            return (this.orderRestrictions && this.orderRestrictions[this.activeSide.restriction]
                ? this.orderRestrictions[this.activeSide.restriction]
                : {});
        },
        activeOrderQuantityRestriction() {
            return GenerateRestrictions(this.activeOrderRestriction[OrderRestrictionFields.QUANTITY]);
        },
        activeOrderPriceRestriction() {
            return GenerateRestrictions(this.activeOrderRestriction[OrderRestrictionFields.PRICE]);
        },
        activeOrderTotalRestriction() {
            return GenerateRestrictions(this.activeOrderRestriction[OrderRestrictionFields.TOTAL]);
        },
        quantityRestriction() {
            return RestrictionsAdapter(this.activeOrderQuantityRestriction);
        },
        priceRestriction() {
            return RestrictionsAdapter(this.activeOrderPriceRestriction);
        },
        totalRestriction() {
            return RestrictionsAdapter(this.activeOrderTotalRestriction);
        },
        getIsOrderPerforming() {
            return this.isOrderPerforming;
        },
        // ask and bid prices getters
        askPrice() {
            if (!this.orderBook.asks.length) {
                return 0;
            }
            return Number(this.orderBook.asks[this.orderBook.asks.length - 1].price);
        },
        bidPrice() {
            if (!this.orderBook.bids.length) {
                return 0;
            }
            return Number(this.orderBook.bids[0].price);
        },
        marketSellPrice() {
            return this.bidPrice;
        },
        marketBuyPrice() {
            return this.askPrice;
        },
    },
    // mutations and actions
    methods: {
        setActiveSideIndex(index) {
            this.activeSideIndex = index;
        },
        // mutations
        // trading
        // limit
        SET_ACTIVE_EXPIRY_INDEX(index) {
            this.activeExpiryIndex = index;
        },
        SET_ACTIVE_FIELD(field) {
            this.activeField = field;
        },
        SET_QUANTITY(quantity) {
            this.quantity = quantity;
        },
        SET_PRICE(price) {
            this.price = price;
        },
        SET_TOTAL(total) {
            this.total = total;
        },
        SET_IS_ORDER_PERFORMING_STATUS(status) {
            this.isOrderPerforming = status;
        },
        // actions
        // trading

        // limit
        setIsOrderPerformingStatus(status) {
            this.SET_IS_ORDER_PERFORMING_STATUS(status);
        },
        setActiveExpiryIndex(index) {
            this.SET_ACTIVE_EXPIRY_INDEX(index);
        },
        setActiveField(field) {
            this.SET_ACTIVE_FIELD(field);
        },
        setQuantity(quantity) {
            this.SET_QUANTITY(quantity);
            this.setActiveField(this.activeFieldValues.QUANTITY);
        },
        setPrice(price) {
            this.SET_PRICE(price);
        },
        setTotal(total) {
            this.SET_TOTAL(total);
            this.setActiveField(this.activeFieldValues.TOTAL);
        },
        resetFields() {
            this.setQuantity(0);
            this.setPrice(0);
        },
        async validateOrder() {
            let error: string | null = null;

            const quantity = Number(this.getQuantity);
            const price = Number(this.getPrice);
            const total = Number(this.getTotal);

            const validateValue = (field, value, restrictions) => {
                if (restrictions.min !== null && value < restrictions.min) {
                    return `${field} should be above ${restrictions.min}`;
                } if (restrictions.max !== null && value > restrictions.max) {
                    return `${field} should be below ${this.activeOrderPriceRestriction.max}`;
                } if (restrictions.stepSize !== null && !restrictions.stepSize.isAliquotOf(value)) {
                    return `${field} have be divide by ${restrictions.stepSize}`;
                } if (value === 0) {
                    return `${field} have be above zero`;
                }
                return null;
            };

            const quantityValidate = validateValue('Quantity', quantity, this.activeOrderQuantityRestriction);
            const priceValidate = validateValue('Price', price, this.activeOrderPriceRestriction);
            const totalValidate = validateValue('Total', total, this.activeOrderTotalRestriction);

            if (quantityValidate !== null) {
                error = quantityValidate;
            } else if (priceValidate !== null) {
                error = priceValidate;
            } else if (totalValidate !== null) {
                error = totalValidate;
            }

            if (error !== null) {
                throw {
                    type: 'VALIDATION_ERROR',
                    message: error,
                };
            }
        },
        async performOrder() {
            try {
                this.setIsOrderPerformingStatus(true);

                await this.validateOrder();

                const order = {
                    type: OrdersTypes.LIMIT.value,
                    accountId: this.$store.getters['Accounts/activeAccountID'],
                    assetPairId: this.$store.getters['AssetPairs/GET_ASSET_PAIRS'].find(({ symbol }) => symbol === this.pair)?.id,
                    placementId: this.$store.getters['Placements/placements'].find(({ name }) => name === this.placement)?.id,
                    side: this.activeSide.value,
                    timeInForce: this.activeExpiry.value,
                    quantity: this.getQuantity.toString(),
                    price: this.getPrice.toString(),
                    total: this.getTotal.toString(),
                    placementOrderTypes: this.$store.getters['Placements/placements'].find(({ name }) => name === this.placement)?.orderQuantityTypes ?? [],
                };
                try {
                    const res = await this.placeOrder(new LimitOrder(order));
                    this.resetFields();
                    return res;
                } finally {
                    this.setIsOrderPerformingStatus(false);
                }
            } catch (error) {
                if ((error as any).type === 'VALIDATION_ERROR') {
                    await this.$store.dispatch('Notificator/showErrorNotification', (error as Error).message, { root: true });
                }

                this.setIsOrderPerformingStatus(false);
            }
        },
        async placeOrder(order) {
            try {
                return await TradingApi.privateOrdersPlaceOrder(new PlaceOrderRequest(order));
            } catch (e) {
                return Promise.reject(e);
            }
        },
        async removeSnapshot(placement: string, pair: string) {
            if (!channelsCallbacks[`orderbooks:${this.$store.state.Placements.placementNamesToPlacementTags.get(placement.toUpperCase())}-${pair}`]) {
                if (this.orderBookSubscriptionIndex?.uid) {
                    try {
                        await removeSnapshot(this.orderBookSubscriptionIndex.uid, placement, pair);
                    } catch {
                        // WASM error
                    }
                }
            }
        },
        async updateOrderBook() {
            try {
                if (this.orderBookSubscriptionIndex?.uid) {
                    try {
                        const { asks, bids } = JSON.parse(
                            await getSnapshot(
                                this.orderBookSubscriptionIndex.uid,
                                this.placement,
                                this.pair,
                                JSON.stringify({
                                    grouping: this.orderBook.grouping,
                                    cumulativeVolume: true,
                                    maxLevelsCount: 10,
                                }),
                            ),
                        );
                        this.orderBook.asks = asks;
                        this.orderBook.bids = bids;
                    } catch {
                        this.orderBook.asks = [];
                        this.orderBook.bids = [];
                    }
                }
            } catch (error) {
                console.log('error in order book', error);
                // code crushed
            }
        },
        async orderBookSubscribe() {
            if (this.orderBookSubscriptionIndex !== null) {
                this.orderBook.asks = [];
                this.orderBook.bids = [];
                await this.$store.dispatch(unsubscribeChannel(this.orderBookSubscriptionIndex));
                try {
                    await this.removeSnapshot(this.orderBook.previousPlacement, this.orderBook.previousPair);
                } catch {
                    // WASM error
                }
                this.orderBookSubscriptionIndex = null;
            }
            this.orderBook.previousPlacement = this.placement;
            this.orderBook.previousPair = this.pair;
            this.orderBookSubscriptionIndex = await this.$store.dispatch(subscribe({
                channel: `orderbooks:${this.$store.state.Placements.placementNamesToPlacementTags.get(this.placement.toUpperCase())}-${this.pair}`,
                callbacks: {
                    subscribe: async () => {
                        try {
                            const { data: res } = await MarketDataApi.publicGetOrderBook(new OrderBookRequest({
                                placementName: this.placement,
                                symbol: this.pair,
                            }));
                            if (typeof res !== 'number') {
                                if (this.orderBookSubscriptionIndex?.uid) {
                                    try {
                                        await setSnapshot(
                                            this.orderBookSubscriptionIndex.uid,
                                            this.placement,
                                            this.pair,
                                            JSON.stringify(res.serialize()),
                                        );
                                    } catch {
                                        // WASM error
                                    }
                                    try {
                                        const params = JSON.parse(
                                            await getSnapshotParams(
                                                this.orderBookSubscriptionIndex.uid,
                                                this.placement,
                                                this.pair,
                                            ),
                                        );
                                        this.orderBook.grouping = params.grouping;
                                    } catch {
                                        // WASM error
                                    }

                                    await this.updateOrderBook();
                                }
                            }
                        } catch (error) {
                            // code crushed because of network error
                        }
                    },
                    publish: async (data: any) => {
                        try {
                            if (this.orderBookSubscriptionIndex?.uid) {
                                try {
                                    await applyDelta(
                                        this.orderBookSubscriptionIndex.uid,
                                        this.placement,
                                        this.pair,
                                        JSON.stringify(data),
                                    );
                                } catch {
                                    // WASM error
                                }
                                if (data.type === 'snapshot') {
                                    try {
                                        const params = JSON.parse(await getSnapshotParams(
                                            this.orderBookSubscriptionIndex.uid,
                                            this.placement,
                                            this.pair,
                                        ));
                                        this.orderBook.grouping = params.grouping;
                                    } catch {
                                        // WASM error
                                    }
                                }
                                await this.updateOrderBook();
                            }
                        } catch (error) {
                            // code crushed
                        }
                    },
                },
                key: 'sss',
            }));
        },
        async tradesSubscribe() {
            if (this.tradesBookSubscriptionIndex !== null) {
                this.marketPrice = 0;
                await this.$store.dispatch(unsubscribeChannel(this.tradesBookSubscriptionIndex));
            }

            this.tradesBookSubscriptionIndex = await this.$store.dispatch(subscribe({
                channel: `trades:${this.$store.state.Placements.placementNamesToPlacementTags.get(this.placement.toUpperCase())}-${this.pair}`,
                callbacks: {
                    subscribe: async () => {
                        const { data: res } = await MarketDataApi.getPublicSpotTrades(new SpotTradesRequest({ placementName: this.placement, symbol: this.pair }));
                        if (Array.isArray(res)) {
                            res.sort(({ timestamp: aTimestamp }, { timestamp: bTimestamp }) => {
                                if (aTimestamp! > bTimestamp!) {
                                    return -1;
                                }
                                if (bTimestamp! > aTimestamp!) {
                                    return 1;
                                }
                                return 1;
                            });
                            this.marketPrice = res.length > 0 ? (res[0].price ?? 0) : 0;
                        }
                    },
                    publish: ({ data }: { data }) => {
                        if (data.type === 'snapshot') {
                            data.trades.sort(({ timestamp: aTimestamp }, { timestamp: bTimestamp }) => {
                                if (aTimestamp! > bTimestamp!) {
                                    return -1;
                                }
                                if (bTimestamp! > aTimestamp!) {
                                    return 1;
                                }
                                return 1;
                            });
                            this.marketPrice = data.trades[0].price!;
                        } else {
                            this.marketPrice = data.trades[data.trades.length - 1].price;
                            data.trades.reverse();
                        }
                    },
                },
            }));
        },
        async getFeesRate(isFirstDownload: boolean) {
            if (isFirstDownload) {
                await this.$store.dispatch('VuexEventListener/addActionListener', {
                    type: 'Accounts/setActiveAccount',
                    callback: () => this.getFeesRate(false),
                }, { root: true });
            }
            const activeAccountId = this.$store.getters['Accounts/activeAccountID'];
            const activePlacementId = this.$store.getters['Placements/getPlacementIdByName'](this.placement);
            const activeAssetPairSymbol = this.pair;
            if (!activeAccountId || !activePlacementId || !activeAssetPairSymbol) {
                return;
            }
            try {
                const { data: feesRate } = await TradingApi.privateGetTradingCommissionRate(new TradingCommissionRateRequest({
                    accountId: activeAccountId,
                    placementId: activePlacementId,
                    spotAssetPairSymbol: activeAssetPairSymbol,
                }));
                this.SET_FEES_RATE(feesRate);
            } catch {
                this.SET_FEES_RATE(null);
            }
        },
        SET_FEES_RATE(feesRate) {
            this.personalFeesRate = feesRate;
        },
    },
    async mounted() {
        if (this.pair && this.placement) {
            this.lastPlacementName = this.placement;
            this.lastPairSymbol = this.pair;
            await this.orderBookSubscribe();
            await this.tradesSubscribe();
            try {
                await this.getFeesRate(true);
            } catch {
                // code crushed because of network error
            }
        }
    },
    async beforeDestroy() {
        if (this.tradesBookSubscriptionIndex !== null) {
            this.marketPrice = 0;
            await this.$store.dispatch(unsubscribeChannel(this.tradesBookSubscriptionIndex));
            this.tradesBookSubscriptionIndex = null;
        }
        if (this.orderBookSubscriptionIndex !== null) {
            this.orderBook.asks = [];
            this.orderBook.bids = [];
            await this.$store.dispatch(unsubscribeChannel(this.orderBookSubscriptionIndex));
            this.orderBookSubscriptionIndex = null;
        }
        this.lastPairSymbol = '';
        this.lastPlacementName = '';
    },
    watch: {
        async pair(value) {
            if (value && this.placement && (value !== this.lastPairSymbol || this.placement !== this.lastPlacementName)) {
                this.lastPlacementName = this.placement;
                this.lastPairSymbol = value;
                await this.orderBookSubscribe();
                await this.tradesSubscribe();
                try {
                    await this.getFeesRate(false);
                } catch {
                    // code crushed because of network error
                }
            }
        },
        async placement(value) {
            if (value && this.pair && (value !== this.lastPlacementName || this.pair !== this.lastPairSymbol)) {
                this.lastPlacementName = value;
                this.lastPairSymbol = this.pair;
                await this.orderBookSubscribe();
                await this.tradesSubscribe();
                try {
                    await this.getFeesRate(false);
                } catch (error) {
                    // code crushed because of network error
                }
            }
        },
    },
});
